<!-- eslint-disable vue/multi-word-component-names -->
<script setup>
</script>

<template>
  <div class="account-layout">
    <AccountSideNav />
    <slot />
  </div>
</template>

<style lang='scss'>
.account-layout {
  display: grid;
  // grid: [stack] 1fr / min-content [stack] 1fr;
  grid-template-columns: max-content [stack] 1fr;
  grid-template-rows: [stack] 1fr;
  min-height: 100vh;
}

@media (max-width: 540px) {
  .account-layout>* {
    grid-area: stack;
  }
}
</style>